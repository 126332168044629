import React from 'react';
import { makeStyles, Typography, Grid, Box, Container, Button } from '@material-ui/core';
import Image from '../images/papo-de-visao.webp';

const useStyles = makeStyles((theme) => ({
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   buttonOutline: {
      color: theme.palette.common.black,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      fontWeight: 'bold',
      textTransform: 'none',
      padding: '0.5rem 2rem 0.75rem 2rem',
      fontSize: '1rem',
      fontFamily: 'Neue Plak Wide Semibold',
      marginTop: '3rem',
      '&:hover': {
         backgroundColor: theme.palette.primary.main,
         color: 'white',
         boxShadow: '0px 6px 30px #ccc',
      }
   },
}));

export default function Header(props) {
   const classes = useStyles();
   return (
      <Box id='papo' mt={{ xs: 6, md: 30 }} px={{ xs: 4, md: 0 }}>
         <Container>
            <Grid container justifyContent='space-between' alignItems='center'>
               <Grid item md={4}>
                  <Box mb={{ xs: 12, md: 0 }}>
                     <Box mb={6}>
                        <Typography variant='h2' component='h1'>
                           <Box component='span' className={classes.borderText}>Papo</Box> de visão
                        </Typography>
                     </Box>
                     <Typography variant='body1' component='p' className={classes.contrastText}>
                        Nós acreditamos que a roupa que vestimos é o nosso cartão de visitas e,
                        por isso, empregamos todo nosso cuidado e carinho no desenvolvimento de
                        peças e modelos com alto padrão e controle de qualidade através da nossa
                        confecção própria e time de designers.
                     </Typography>
                     <Button
                        className={classes.buttonOutline}
                        color='secondary'
                        href='/colecoes'>
                        Conheça nossos modelos
                     </Button>
                  </Box>
               </Grid>
               <Grid item md={7}>
                  <img src={Image} alt='Quebra Vento' width='100%' />
               </Grid>
            </Grid>
         </Container>
      </Box>
   );
}
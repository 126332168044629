import React from 'react';
import { makeStyles, Grid, Box, Typography, Button, Container } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Manifesto from '../components/Manifesto';
import PapoDeVisao from '../components/PapoDeVisao';
import Footer from '../components/Footer';

// Images
import ImageHeader from '../images/header.webp';
import BgLojas from '../images/bg-lojas.webp';
import Vetor from '../images/vetor.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   classTitle: {
      lineHeight: '3.5rem',
      color: theme.palette.common.black,
      borderWidth: '0 0 3px 0',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
   },
   classSubtitle: {
      color: theme.palette.common.black,
   },
   classImage: {
      marginLeft: '10px',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '0',
      }
   },
   manifestoBg: {
      backgroundImage: `url(../images/manifesto-bg.webp)`,
   },
   bgLojas: {
      backgroundImage: `url(${BgLojas})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom center',
      width: '100%',
      height: '379px',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      }
   },
   white: {
      color: theme.palette.common.white,
   },
   buttonOutline: {
      color: theme.palette.common.white,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      fontWeight: 'bold',
      textTransform: 'none',
      padding: '0.5rem 2rem 0.75rem 2rem',
      fontSize: '1rem',
      fontFamily: 'Neue Plak Wide Semibold',
      marginTop: '3rem',
      '&:hover': {
         backgroundColor: theme.palette.primary.main,
         color: 'white',
         boxShadow: '0px 6px 30px #ccc',
      }
   },
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>
         <TopBar />
         <Header
            classTitle={classes.classTitle}
            title='Quebra Vento'
            classSubTitle={classes.classSubTitle}
            subtitle='Juntos em cada onda, cada ollie, cada esquina.'
            image={ImageHeader}
            imageAlt='Quebra Vento'
            classBoxImage={classes.classBoxImage}
            classImage={classes.classImage}
            buttonLabel='Nossas lojas'
            linkButton='/lojas'
         />
         <Manifesto />
         <PapoDeVisao />

         <Box mt={{ xs: 12, md: 24 }}>
            <img src={Vetor} alt='Quebra Vento' width='100%' />
         </Box>
         
         {/* Lojas */}
         <Box mt={0} px={{ xs: 4, md: 0 }} className={classes.bgLojas}>
            <Box pt={{ xs: 12, md: 36 }} pb={{ xs: 18, md: 36 }}>
               <Container>
                  <Grid container justifyContent='space-between' alignItems='center'>
                     <Grid item md={3}>
                        <Typography variant='h2' component='h1' className={classes.white}>
                           Nossas Lojas
                        </Typography>
                     </Grid>
                     <Grid item md={3}>
                        <Typography variant='body1' component='p' className={classes.white}>
                           Somos 13 lojas no estado do Rio de Janeiro, encontre uma mais próxima de você.
                        </Typography>
                     </Grid>
                     <Grid item md={3}>
                        <Button
                           className={classes.buttonOutline}
                           href='/lojas'>
                           Ver Lojas
                        </Button>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </Box>

         <Footer />
      </Grid >
   );
}
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Lojas from '../pages/Lojas';
import Colecoes from '../pages/Colecoes';
import QuemSomos from '../pages/QuemSomos';

export default function Rotas() {
   return (
      <Router>
         <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/lojas" element={<Lojas/>} />
            <Route path="/colecoes" element={<Colecoes/>} />
            <Route path="/quem-somos" element={<QuemSomos/>} />
         </Routes>
      </Router>
   );
}
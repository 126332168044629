import React from 'react';
import { makeStyles, Button, MenuItem, Fade, Menu, IconButton, Box, Link } from '@material-ui/core';

//ICONS
import MenuIcon from '@material-ui/icons/Menu';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles((theme) => ({
  hambMenu: {
    color: 'black',
    fontSize: '2rem',
  },
  menuItem: {
    padding: '.5rem 1.5rem',
    fontSize: '1rem',
    lineHeight: '2rem',
    fontFamily: 'Roboto, sans-serif',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:focus, &:hover': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'rgba(0,0,0,0.7)',
      '&:focus, &:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
      },
    },
  },
  icons: {
    //paddingRight: theme.spacing(0),
    color: 'white',
    fontSize: '1rem',
    paddingLeft: '.6rem',
    paddingRight: '.6rem',
    [theme.breakpoints.down('sm')]: {
      color: 'rgba(0,0,0,0.6)',
    },
  },
}));


export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon className={classes.hambMenu} />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={classes.MenuMobile}
      >
        <MenuItem onClick={handleClose}>
          <a
            className={classes.menuItem}
            href='/'>
            Home
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            className={classes.menuItem}
            href='/quem-somos'>
            Manifesto
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            className={classes.menuItem}
            href='/colecoes'>
            Nossos modelos
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            className={classes.menuItem}
            href='/lojas'>
            Nossas Lojas
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className={classes.menuItem}
            href='/#contatos' to="contatos"
            smooth={true} spy={true} duration={700}>
            Contatos
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems='center' flexWrap="nowrap" width="100%">
            <IconButton href='https://www.instagram.com/quebra_vento/' target='_blank' className={classes.icons}>
              <InstagramIcon />
            </IconButton>
            <IconButton href='https://www.facebook.com/UseQuebraVento/' target='_blank' className={classes.icons}>
              <FacebookIcon />
            </IconButton>
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
}

import React from 'react';
import {
  makeStyles, Container, Typography, Grid, Box, Link
} from '@material-ui/core';

// Icons
import { WhatsApp, Phone } from '@material-ui/icons';
import { Icon } from '@iconify/react';

// Images
import Logo from '../images/logo-white.webp';

const useStyles = makeStyles((theme) => ({
  bg: {
    background: theme.palette.secondary.dark,
  },
  white: {
    color: theme.palette.common.white,
  },
  hr: {
    height: '0',
    border: '1px solid rgba(236, 239, 246, 0.1)',
    margin: '2rem 0 1rem 0',
  },
  icon: {
    fontSize: '1.25rem',
    margin: '0.5rem 0.3rem 0 0.3rem',
    color: theme.palette.common.white,
    // width: '1.25rem !important',
    // height: '1.25rem !important',
    // margin: '0 0.5rem 0 0',
  },
  link: {
    color: theme.palette.common.white,
    display: 'block',
    marginBottom: '0.25rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0',
    fontFamily: 'Neue Plak Wide Regular',
  },
  buttonOutlined: {
    width: '100%',
    color: theme.palette.primary.main,
    boxShadow: 'none',
    borderRadius: '25px',
    textTransform: 'none',
    padding: '0.5rem 2.5rem',
    fontSize: '1rem',
    fontWeight: '700',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (

    <Box id='contatos' className={classes.bg} pt={20} pb={10} px={6}>
      <Container>

        <Grid container justifyContent='flex-start'>

          {/* Logo */}
          <Grid item xs={4} md={1}>
            <Box mb={8}>
              <img src={Logo} alt='Logo' width='100%' />
            </Box>
          </Grid>

          <Grid item md={2}></Grid>

          {/* Navegue nas páginas */}
          <Grid item xs={12} md={3}>
            <Box mb={4}>
              <Typography variant='body1' component='h5' className={classes.white}>Navegue nas páginas</Typography>
            </Box>
            <Link className={classes.link} href='/'>Home</Link>
            <Link className={classes.link} href='/#manifesto'>Manifesto</Link>
            <Link className={classes.link} href='/#papo'>Papo de visão</Link>
            <Link className={classes.link} href='/colecoes'>Modelos e coleções</Link>
            <Link className={classes.link} href='/lojas'>Nossas lojas</Link>
          </Grid>

          {/* Central de atendimento */}
          <Grid item md={3}>
            <Typography variant='body1' component='h5' className={classes.white}>Central de atendimento</Typography>
            <Box mt={4} mb={8}>
              <Grid container justifyContent='flex-start' alignItems='center' alignContent='center'>
                <Grid item>
                  <Phone className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography variant='body2' component='p' className={classes.white}>
                    (21) 2751-2205
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {/* <Box mb={8}>
              <Grid container justifyContent='flex-start' alignItems='center' alignContent='center'>
                <Grid item>
                  <WhatsApp className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography variant='body2' component='p' className={classes.white}>
                    
                  </Typography>
                </Grid>
              </Grid>
            </Box> */}
            <Box mb={4}>
              <Typography variant='body1' component='h5' className={classes.white}>Nossas redes sociais</Typography>
            </Box>
            <Box mb={8}>
              <Link className={classes.white} href='https://www.instagram.com/quebra_vento/' target="_blank">
                <Icon icon="fa-brands:instagram" className={classes.icon} />
              </Link>
              <Link className={classes.white} href='https://www.facebook.com/UseQuebraVento/' target="_blank">
                <Icon icon="fa-brands:facebook" className={classes.icon} />
              </Link>
            </Box>
          </Grid>

          {/* descrição */}
          <Grid item md={3} >
            <Box mb={8} pt={6} pr={6}>
              <Typography variant='body2' component='p' className={classes.white}>
                Somos a Quebra vento e você conhece bem.
                Orgulho de ser Carioca.
                Qualidade, elegância e preço justo.
              </Typography>
            </Box>
          </Grid>

        </Grid>

        <hr className={classes.hr} />

        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='body2' component='span' className={classes.white}>
              Copyright © Quebra Vento - Todos os direitos reservados
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2' component='span' className={classes.white}>
              <Box mr={1} component='span'>Desenvolvido por</Box>
            </Typography>
            <Link href='https://www.cwa.digital/' target='_blank' className={`${classes.white} ${classes.fontBold}`}>
              <Typography variant='body2' component='span'>
                Cwa Digital
              </Typography>
            </Link>
          </Grid>
        </Grid>

      </Container>
    </Box>
  );
}
import React from 'react';
import { makeStyles, Grid, Box, Typography, Container, Button } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';

// Images
import BgTitle from '../images/bg-title.webp';
import Foto1 from '../images/foto-1.webp';
import Foto2 from '../images/foto-2.webp';
import Foto3 from '../images/foto-3.webp';
import Foto4 from '../images/foto-4.webp';
import Foto5 from '../images/foto-5.webp';
import Foto6 from '../images/foto-6.webp';
import Foto7 from '../images/foto-7.webp';
import Foto8 from '../images/foto-8.webp';
import Foto9 from '../images/foto-9.webp';
import Foto10 from '../images/foto-10.webp';


const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   bgTitle: {
      backgroundImage: `url(${BgTitle})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom center',
      width: '100%',
      height: '379px',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      }
   },
   classTitle: {
      lineHeight: '3.5rem',
      color: theme.palette.common.black,
      borderWidth: '0 0 3px 0',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   contrasText: {
      color: theme.palette.common.black,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   buttonOutline: {
      color: theme.palette.common.black,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      fontWeight: 'bold',
      textTransform: 'none',
      padding: '0.5rem 2rem 0.75rem 2rem',
      fontSize: '1rem',
      fontFamily: 'Neue Plak Wide Semibold',
      '&:hover': {
         backgroundColor: theme.palette.primary.main,
         color: 'white',
         boxShadow: '0px 6px 30px #ccc',
      }
   },
}));

export default function Colecoes() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>
         <TopBar />

         <Box mt={28} px={{ xs: 4, md: 0 }} className={classes.bgTitle}>
            <Grid container justifyContent='center'>
               <Grid item>
                  <Box pt={{ xs: 12, md: 36 }} pb={{ xs: 18, md: 36 }}>
                     <Typography variant='h2' component='h1' className={classes.white}>
                        Nossos modelos
                     </Typography>
                  </Box>
               </Grid>
            </Grid>
         </Box>

         <Box mt={{ xs: 14, md: 28 }} px={{ xs: 4, md: 0 }} textAlign='center'>
            <Container>
               <Grid container justifyContent='center'>
                  <Grid item md={6}>
                     <Typography variant='body1' component='p' className={classes.contrasText}>
                        Aqui você conhece alguns dos nossos modelos.
                        Confira os endereços e contatos das nossas lojas para conferir toda a nossa coleção.
                     </Typography>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Box my={{ xs: 8, md: 16 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='flex-start' alignItems='flex-start' spacing={6}>
                  <Grid item md={4}>
                     <img src={Foto4} width='100%' alt='Quebra Vento' />
                  </Grid>
                  <Grid item md={4}>
                     <img src={Foto5} width='100%' alt='Quebra Vento' />
                  </Grid>
                  <Grid item md={4}>
                     <img src={Foto3} width='100%' alt='Quebra Vento' />
                  </Grid>
                  <Grid item md={6}>
                     <img src={Foto1} width='100%' alt='Quebra Vento' />
                  </Grid>
                  <Grid item md={6}>
                     <img src={Foto2} width='100%' alt='Quebra Vento' />
                  </Grid>
                  <Grid item md={6}>
                     <Grid container justifyContent='flex-start' spacing={6}>
                        <Grid item md={6}>
                           <img src={Foto10} width='100%' alt='Quebra Vento' />
                        </Grid>
                        <Grid item md={6}>
                           <img src={Foto7} width='100%' alt='Quebra Vento' />
                        </Grid>
                        <Grid item md={6}>
                           <img src={Foto8} width='100%' alt='Quebra Vento' />
                        </Grid>
                        <Grid item md={6}>
                           <img src={Foto9} width='100%' alt='Quebra Vento' />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item md={6}>
                     <img src={Foto6} width='100%' alt='Quebra Vento' />
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Box mt={{ xs: 8, md: 16 }} mb={{ xs: 16, md: 24 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='center'>
                  <Grid item>
                     <Button
                        className={classes.buttonOutline}
                        href='/lojas'>
                        Conheças nossas lojas
                     </Button>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Footer />
      </Grid>
   );
}
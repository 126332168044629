import React from 'react';
import { Button, makeStyles, Typography, Container, Grid, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   section: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left bottom',
      backgroundColor: '#FFFFFF',
   },
   container: {
      // minHeight: '100vh',
      paddingTop: '1rem',
   },
   // button: {
   //    padding: '0.75rem 4rem',
   //    marginTop: '3rem',
   //    color: 'white',
   //    borderRadius: '4px',
   // },
   buttonLabel: {
      textTransform: 'none',
      fontWeight: 'bold',
   },
   buttonOutline: {
      color: theme.palette.common.black,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      fontWeight: 'bold',
      textTransform: 'none',
      padding: '0.5rem 2rem 0.75rem 2rem',
      fontSize: '1rem',
      fontFamily: 'Neue Plak Wide Semibold',
      marginTop: '3rem',
      '&:hover': {
         backgroundColor: theme.palette.primary.main,
         color: 'white',
         boxShadow: '0px 6px 30px #ccc',
      }
   },
}));

export default function Header(props) {
   const classes = useStyles();
   return (
      <Box mt={{ xs: 24, md: 0 }} px={{ xs: 4, md: 0 }}>
         <Grid className={classes.section}>
            <Container maxWidth="xl">
               <Grid container className={classes.container} direction='row' justifyContent='center' alignItems='center'>
                  <Grid item md='3' className={props.classBoxText} alignContent='flex-end'>
                     <Box textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography variant='h2' component='h1'>
                           <Box component='span' className={props.classTitle}>{props.title}</Box>
                        </Typography>
                        <Typography variant='h2' component='h2'>
                           <span className={props.classSubtitle}>{props.subtitle}</span>
                        </Typography>
                        <Button className={props.button} color='secondary' href={props.linkButton}
                           classes={{
                              root: classes.buttonOutline,
                              label: classes.buttonLabel,
                           }}>{props.buttonLabel}
                        </Button>
                     </Box>
                  </Grid>
                  <Grid item md='8' className={props.classBoxImage}>
                     <Box mt={{ xs: 0, md: 0 }} pl={{ xs: 0, md: 4 }} pr={{ xs: 0, md: 0 }}>
                        <img src={props.image} alt={props.imageAlt} className={props.classImage} width='100%' p={{ xs: '0' }} />
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Grid>
      </Box>
   );
}
import React from 'react';
import { makeStyles, Grid, Box, Typography, Link } from '@material-ui/core';

// Icons
import StorefrontIcon from '@mui/icons-material/Storefront';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   white: {
      color: theme.palette.common.white,
   },
   contrastText: {
      color: `${theme.palette.secondary.dark} !important`,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   box: {
      background: '#F2F2F2',
      width: '100%',
      maxHeight: '230px',
   },
   container: {
      height: '230px',
   },
   icon: {
      color: theme.palette.primary.main,
      fontSize: '1.25rem !important',
      marginTop: '0.15rem !important',
      marginRight: '0.25rem !important',
      float: 'left',
      '&hover': {
         color: `${theme.palette.primary.main} !important`,
      },
   },
   link: {
      cursor: 'pointer',
      '&hover': {
         color: `${theme.palette.primary.main} !important`,
      },
   }
}));

export default function Card(props) {
   const classes = useStyles();
   return (
      <Grid item xs={12} md={3}>
         <Box p={6} className={classes.box}>
            <Grid container justifyContent='center'>
               <Grid item xs={2}>
                  <StorefrontIcon className={classes.icon} />
               </Grid>
               <Grid item xs={10}>
                  <Grid container className={classes.container} direction='column' justifyContent='flex-start'>
                     <Grid item>
                        <Box mb={4}>
                           <Typography variant='h4' component='h4' className={classes.primary}>
                              {props.loja}
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item>
                        <Box mb={4}>
                           <Typography variant='body2' component='p' className={classes.contrastText}>
                              {props.endereco}<br />
                              CEP: {props.cep}
                           </Typography>
                        </Box>
                     </Grid>
                     <Box mb={2} className={props.telClass}>
                        <Link href={`tel:+550${props.tel}`} className={classes.contrastText}>
                           <Typography variant='body2' component='p'>
                              <PhoneIphoneIcon className={`${classes.icon} ${classes.contrastText}`} />
                              {props.tel}
                           </Typography>
                        </Link>
                     </Box>
                     <Grid item>
                        <Link href={props.whatsAppLink}>
                           <Typography variant='body2' component='p' className={`${classes.link} ${classes.contrastText}`}>
                              <WhatsAppIcon className={`${classes.icon} ${classes.contrastText}`} />
                              {props.whatsApp}
                           </Typography>
                        </Link>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Box>
      </Grid>
   );
}
import React from 'react';
import { makeStyles, Grid, Box, Typography, Container } from '@material-ui/core';
import TopBarHome from '../components/TopBarHome';
import Footer from '../components/Footer';

// Images
import BgTitle from '../images/bg-title.webp';
import Image1 from '../images/foto-1.webp';
import Image2 from '../images/manifesto.webp';
import Vetor from '../images/vetor.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   classTitle: {
      lineHeight: '3.5rem',
      color: theme.palette.common.black,
      borderWidth: '0 0 3px 0',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
   },
   classSubtitle: {
      color: theme.palette.common.black,
   },
   classImage: {
      marginLeft: '10px',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '0',
      }
   },
   manifestoBg: {
      backgroundImage: `url(../images/manifesto-bg.webp)`,
   },
   bgTitle: {
      backgroundImage: `url(${BgTitle})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom center',
      width: '100%',
      height: '379px',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      }
   },
   white: {
      color: theme.palette.common.white,
   },
   borderText: {
      lineHeight: '3.5rem',
      color: theme.palette.common.black,
      borderWidth: '0 0 3px 0',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
   },
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>
         <TopBarHome />

         <Box mt={28} px={{ xs: 4, md: 0 }} className={classes.bgTitle}>
            <Grid container justifyContent='center'>
               <Grid item>
                  <Box pt={{ xs: 12, md: 36 }} pb={{ xs: 18, md: 36 }}>
                     <Typography variant='h2' component='h1' className={classes.white}>
                        Nosso Manifesto
                     </Typography>
                  </Box>
               </Grid>
            </Grid>
         </Box>

         <Box mt={{ xs: 6, md: 30 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='space-around' alignItems='center'>
                  <Grid item md={4}>
                     <Box mb={{ xs: 12, md: 0 }}>
                        <Box mb={8}>
                           <Typography variant='h2' component='h1'>
                              Nada é permanente, exceto <Box component='span' className={classes.borderText}>a mudança.</Box>
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography variant='body1' component='p' className={classes.contrastText}>
                              Em 1989 nascemos, nos tumultuados anos 90 crescemos, nos agitados anos 2000 nos multiplicamos e hoje, 33 anos depois, podemos dizer que nossa história, que andou lado a lado com a sua, está só começando.
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography variant='body1' component='p' className={classes.contrastText}>
                              Vivemos sonhos, conquistas, decepções e desafios juntos. Crescemos e vimos você crescer. Nos afastamos um pouco? Talvez sim. Mas essa vida é mesmo cheia de idas e vindas, mas quando olhamos para trás podemos enxergar quem realmente fez e faz parte da nossa história. Hoje estamos aqui, escrevendo histórias juntos sem deixar morrer o sonho que sempre nos moveu.
                           </Typography>
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item md={5}>
                     <img src={Image1} alt='Quebra Vento' width='100%' />
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Box mt={{ xs: 6, md: 30 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='space-evenly' alignItems='center'>
                  <Grid item md={4}>
                     <Box mb={{ xs: 8, md: 0 }}>
                        <img src={Image2} alt='Quebra Vento' width='100%' />
                     </Box>
                  </Grid>
                  <Grid item md={4}>
                     <Box pl={{ xs: 0, md: 18 }} mb={{ xs: 12, md: 0 }}>
                        <Box mb={8}>
                           <Typography variant='body1' component='p' className={classes.contrastText}>
                              Nós fomos à festas e cinemas juntos, talvez, em seu primeiro encontro, nós também estávamos lá, é inegável que tudo o que vivemos foi incrível, mas acredite: o que temos por vir é ainda melhor.
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography variant='body1' component='p' className={classes.contrastText}>
                              A jornada só faz sentido se caminharmos juntos, em cada onda, cada ollie, cada esquina, cada noite, estaremos juntos com você.
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography variant='body1' component='p' className={classes.contrastText}>
                              Partiu!
                           </Typography>
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item md={1}></Grid>
               </Grid>
            </Container>
            <Box mt={{ xs: 12, md: 12 }}>
               <img src={Vetor} alt='Quebra Vento' width='100%' />
            </Box>
         </Box>

         <Footer />
      </Grid >
   );
}

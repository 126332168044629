import React from 'react';
import { makeStyles, Typography, Grid, Box, Button } from '@material-ui/core';
import Image from '../images/manifesto.webp';
import ImageBg from '../images/manifesto-bg.webp';
import Pattern from '../images/pattern.webp';

const useStyles = makeStyles((theme) => ({
   bg: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left top',
      backgroundColor: '#FFFFFF',
      backgroundImage: `url('${ImageBg}')`,
      height: '600px',
      [theme.breakpoints.down('sm')]: {
         backgroundImage: 'none',
      }
   },
   container: {
      height: '470px',
      position: 'relative',
   },
   borderText: {
      lineHeight: '3.5rem',
      color: theme.palette.common.black,
      borderWidth: '0 0 3px 0',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   boxImage: {
      backgroundColor: '#FAFAFA',
      padding: '1rem 0 1rem 1rem',
   },
   PatternImage: {
      width: '480px',
      height: '24px',
      backgroundImage: `url('${Pattern}')`,
   },
   PatternText: {
      width: '105%',
      height: '470px',
      backgroundImage: `url('${Pattern}')`,
      position: 'absolute',
      top: '22px',
      left: '0',
      zIndex: '0',
      [theme.breakpoints.down('sm')]: {
         height: '340px',
         top: '40px',
      }
   },
   boxText: {
      backgroundColor: '#FAFAFA',
      padding: '4rem',
      height: '470px',
      position: 'relative',
      zIndex: '1',
      [theme.breakpoints.down('sm')]: {
         padding: '0rem 1.75rem',
         height: '320px',
      }
   },
   buttonOutline: {
      color: theme.palette.common.black,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      fontWeight: 'bold',
      textTransform: 'none',
      padding: '0.5rem 2rem 0.75rem 2rem',
      fontSize: '1rem',
      fontFamily: 'Neue Plak Wide Semibold',
      marginTop: '3rem',
      '&:hover': {
         backgroundColor: theme.palette.primary.main,
         color: 'white',
         boxShadow: '0px 6px 30px #ccc',
      }
   },
   right: {
      textAlign: 'right',
   },
}));

export default function Header(props) {
   const classes = useStyles();
   return (
      <Box id='manifesto' px={{ xs: 0, md: 0 }}>
         <Grid container justifyContent='flex-start' alignItems='center'>
            <Grid container md={6} className={classes.bg} justifyContent='flex-end' alignItems='center'>
               <Grid container className={classes.container} justifyContent='flex-end' alignItems='center'>
                  <Grid item>
                     <Box className={classes.boxImage}>
                        <img src={Image} alt='Quebra Vento' />
                     </Box>
                  </Grid>
                  <Grid item>
                     <Box className={classes.PatternImage}></Box>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container md={4}>
               <Grid container className={classes.container} justifyContent='flex-end' alignItems='center'>
                  <Grid item>
                     <Box className={classes.boxText}>
                        <Box mb={6}>
                           <Typography variant='h2' component='h1'>
                              Manifesto <Box component='span' className={classes.borderText}>QV</Box>
                           </Typography>
                        </Box>
                        <Typography variant='body1' component='p' className={classes.contrastText}>
                           Em 1989 nascemos, nos tumultuados anos 90 crescemos,
                           nos agitados anos 2000 nos multiplicamos e hoje, 33 anos depois,
                           podemos dizer que nossa história, que andou lado a lado com a sua,
                           está só começando.
                        </Typography>
                        <Button
                           className={classes.buttonOutline}
                           color='secondary'
                           href='/quem-somos'>
                           Quem Somos
                        </Button>
                     </Box>
                  </Grid>
                  <Grid item>
                     <Box className={classes.PatternText}></Box>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Box>
   );
}

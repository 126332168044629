import React from 'react';
import { makeStyles, Grid, Box, Typography, Container } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import Card from '../components/Card';

// Images
import BgTitle from '../images/bg-lojas.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   bgTitle: {
      backgroundImage: `url(${BgTitle})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom center',
      width: '100%',
      height: '379px',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      }
   },
   classTitle: {
      lineHeight: '3.5rem',
      color: theme.palette.common.black,
      borderWidth: '0 0 3px 0',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   box: {
      background: '#F2F2F2',
      width: '100%',
      height: 'auto',
   },
   icon: {
      color: theme.palette.primary.main,
   },
   telClass: {
      display: 'none',
   }
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>
         <TopBar />

         <Box mt={28} px={{ xs: 4, md: 0 }} className={classes.bgTitle}>
            <Grid container justifyContent='center'>
               <Grid item>
                  <Box pt={{ xs: 12, md: 36 }} pb={{ xs: 18, md: 36 }}>
                     <Typography variant='h2' component='h1' className={classes.white}>
                        Nossas Lojas
                     </Typography>
                  </Box>
               </Grid>
            </Grid>
         </Box>

         <Box my={{ xs: 8, md: 24 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='flex-start' spacing={6}>
                  <Card
                     loja='Bangu'
                     endereco='Rua Prof. Clemente Ferreira 1874 - Bangu'
                     cep='21.810-142'
                     tel='2491-1984'
                     whatsApp='(21) 97719-6130'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521977196130&text=Fala,+Quebra+Vento!'
                  />
                  <Card
                     loja='Bonsucesso'
                     endereco='Rua Cardoso de Moraes, 115 Lj.B - Bonsucesso'
                     cep='21032-000'
                     tel='4108-2934'
                     whatsApp='(21) 96883-1039'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521968831039&text=Fala,+Quebra+Vento!'
                  />
                  <Card
                     loja='Duque de Caxias'
                     endereco='Rua André Rebouças, 31 - Centro'
                     cep='23.815-292'
                     tel='3819-8004'
                     whatsApp='(21) 97046-0990'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521970460990&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Itaguaí'
                     endereco='Rua Dr. Curvelo Cavalcanti, 394 - Centro'
                     cep='23815-290'
                     tel='2687-1563'
                     whatsApp='(21) 97080-4192'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521970804192&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Magé'
                     endereco='Rua João Valério, 01, Ed. Majestique - Centro'
                     cep='25900-000'
                     telClass={classes.telClass}
                     // tel='2633-2354'
                     whatsApp='(21) 97015-3911'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521970153911&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Nilópolis'
                     endereco='Estr. Mirandela, 219 - Centro'
                     cep='26520-335'
                     tel='2691-5747'
                     whatsApp='(21) 99131-6478'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521991316478&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Shopping Rio Ville'
                     endereco='Av. Automóvel Club, 2384 Ljs:1068/1070 - Vilar dos Teles, SJ de Meriti'
                     cep='25515-120'
                     tel='(21) 2651-1116'
                     whatsApp='(21) 97000-4413'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521970004413&text=Fala,+Quebra+Vento!'
                  />
                  <Card
                     loja='Piabetá'
                     endereco='Rua Santa Eliza, 99 – Loja B, Piabetá – Magé'
                     cep='25.915-000'
                     telClass={classes.telClass}
                     // tel='2659-0216'
                     whatsApp='(21) 97004-7028'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521970047028&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Queimados'
                     endereco='R. Eloi Teixeira nº 165-Lj:01 Térreo - Centro'
                     cep='26.383-080'
                     telClass={classes.telClass}
                     // tel='2724-4537'
                     whatsApp='(21) 97015-3910'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521970153910&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Recreio'
                     endereco='Av. Gilka  Machado , nº2 Lj C, Edificada no Lote 01 da Quadra 92 do PAL 17906'
                     cep='22790-570'
                     telClass={classes.telClass}
                     // tel='3518-4579'
                     whatsApp='(21) 96756-4744'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521967564744&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Paracambi'
                     endereco='Rua Dominique Level, 130 Lj – Centro'
                     cep='26.600-000'
                     telClass={classes.telClass}
                     whatsApp='(21) 96777-5757'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521967775757&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Seropédica'
                     endereco='Av. Ministro Fernando Costa, 477-A – Fazenda Caxias'
                     cep='23890-000'
                     telClass={classes.telClass}
                     // tel='3787-0553'
                     whatsApp='(21) 96607-5778'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521966075778&text=Fala,%20Quebra%20Vento!'
                  />
                  <Card
                     loja='Santa Cruz da Serra'
                     endereco='Rod. Washington Luiz, s/nº KM 19 - Qd:B, Lt:01, Lj:A - PQ. Eldorado'
                     cep='25240-005'
                     telClass={classes.telClass}
                     whatsApp='(21) 97000-5874'
                     whatsAppLink='https://api.whatsapp.com/send?phone=5521970005874&text=Fala,%20Quebra%20Vento!'
                  />
               </Grid>
            </Container>
         </Box>

         <Footer />
      </Grid>
   );
}
